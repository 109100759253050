<template>
  <v-list-group :value="true">
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title class="black--text font-weight-bold my-0 py-3">
          Status
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <v-divider />

    <v-list-item class="py-5 light-blue lighten-5">
      <v-row>
        <v-col
          v-for="eventTypeFilterOption in statusFilter"
          :key="`event-type-filter-${eventTypeFilterOption.key}`"
          cols="6"
        >
          <v-chip
            :color="items[eventTypeFilterOption.key] ? 'primary': ''"
            class="d-block text-left body-2 white my-0"
            label
            @click="funcCall(eventTypeFilterOption.key)"
          >
            {{ eventTypeFilterOption.label }}
          </v-chip>
        </v-col>
      </v-row>
    </v-list-item>
  </v-list-group>
</template>

<script>
import filtersComponentMixin from './mixins/filtersComponent';

export default {
  name: 'CurrencyFilter',
  components: {
  },
  data: () => ({
    items: {
      onSale: false,
      notOnSale: false,
      clear: false,
    },
    statusFilter: [
      {
        key: 'onSale',
        label: 'On Sale',

      },
      {
        key: 'notOnSale',
        label: 'Not On Sale',
      },
      {
        key: 'clear',
        label: 'Clear',
      },
    ],
  }),
  mixins: [filtersComponentMixin],
  computed: {
  },
  methods: {
    funcCall(key) {
      if (this.items[key]) {
        this.findClear();
      } else {
        // Mapping of keys to functions
        const actionMap = {
          onSale: this.findOnSale,
          notOnSale: this.findNotOnSale,
        };

        // Call the corresponding function, or findClear if the key doesn't match
        (actionMap[key] || this.findClear)();
      }
    },
    findOnSale() {
      this.items.onSale = !this.items.onSale;
      this.items.notOnSale = false;
      // part handle params
      const currentParams = this.$router.currentRoute.query;
      const mergedParams = { ...currentParams };
      mergedParams.p = 1;
      mergedParams.onSale = true;
      try {
        this.$router.replace({ query: mergedParams });
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      }
    },
    findNotOnSale() {
      this.items.notOnSale = !this.items.notOnSale;
      this.items.onSale = false;
      // part handle params
      const currentParams = this.$router.currentRoute.query;
      const mergedParams = { ...currentParams };
      mergedParams.p = 1;
      mergedParams.onSale = false;
      try {
        this.$router.replace({ query: mergedParams });
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      }
    },
    findClear() {
      this.items.notOnSale = false;
      this.items.onSale = false;
      // part handle params
      const currentParams = this.$router.currentRoute.query;
      const mergedParams = { ...currentParams };
      mergedParams.p = 1;
      mergedParams.onSale = '';
      try {
        this.$router.replace({ query: mergedParams });
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      }
    },
  },
  mounted() {
    const currentParams = this.$router.currentRoute.query;
    const mergedParams = { ...currentParams };
    if (mergedParams.onSale && mergedParams.onSale === 'true') {
      this.onSale = true;
      this.notOnSale = false;
    } else if (mergedParams.onSale && mergedParams.onSale === 'false') {
      this.onSale = false;
      this.notOnSale = true;
    } else {
      this.onSale = false;
      this.notOnSale = false;
    }
  },
};
</script>
